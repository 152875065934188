import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  color: "quaternary",
  ref: "searchPill",
  class: "searchPill",
  "search-pill": ""
}
const _hoisted_2 = { class: "text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("spk-button", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1 /* TEXT */)
  ], 512 /* NEED_PATCH */))
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "search-result-skeleton": "",
  class: "flex flex-col animate-pulse opacity-80"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "h-[220px] bg-bg-placeholder rounded-checkbox" }, null, -1 /* HOISTED */)
  ])))
}

import { defineComponent, PropType } from 'vue';
import { debounce, DebouncedFunc } from 'lodash';
import { disablePageScroll, enablePageScroll, ScrollableTarget } from 'scroll-lock';
import ClearButton from './components/ClearButton.vue';
import SearchResultSkeleton from './components/SearchResultSkeleton.vue';
import GameGrid from './components/GameGrid.vue';
import SearchResult from './components/SearchResult.vue';
import SearchPill from './components/SearchPill.vue';
import { Game, SearchResults, PopularGames, RecentlyPlayedGames, ProviderObject } from './interfaces/interfaces';
import { t } from './utilities/utilities';

declare global {
    interface Window {
        dataLayer?: Record<string, unknown>;
        JWToken?: string;
    }
}

const resizeWidthThreshold = 1023;

// eslint-disable-next-line no-unused-vars
type DebFunc = DebouncedFunc<(...args: unknown[]) => void>;

const abortController = new AbortController();
type AbortControllerInterface = typeof abortController;

let requestStack: AbortControllerInterface[] = [];

const clearRequestStack = (): void => {
    requestStack.forEach((controller) => controller.abort());
    requestStack = [];
};

interface Data {
    focused: boolean;
    windowInnerWidth: number,
    windowInnerHeight: number,
    searchInputFocused: boolean;
    searchPillsEn: string[];
    searchPillsJa: string[];
    isLoading: boolean;
    displayErrorMessage: boolean;
    searchString: string;
    searchResults: SearchResults | null;
    popularGames: PopularGames | null;
    recentlyPlayedGames: RecentlyPlayedGames | null;
    providers: ProviderObject[] | null;
    provider: string;
    isLoadingProviders: boolean;
    isLoadingEnticingGames: boolean;
    showRollingEffect: boolean;
    scrollCounter: number;
    wrapperScrollOffset: number;
    debounceSearch: DebFunc;
    debounceResize: DebFunc;
}

export default defineComponent({
    name: 'Search',
    components: { SearchResultSkeleton, SearchResult, ClearButton, SearchPill, GameGrid },
    props: {
        baseUrl: {
            type: String as PropType<string>,
            required: true,
        },
        webfrontParams: {
            type: Object as PropType<Record<string, string>>,
            required: true,
        },
        translations: {
            type: Object as PropType<Record<string, string>>,
            required: true,
        },
        environment: {
            type: String as PropType<string>,
            required: true,
        },
    },
    provide() {
        return {
            webfrontParams: this.webfrontParams,
        };
    },
    data() {
        return {
            focused: false,
            windowInnerWidth: window.innerWidth,
            windowInnerHeight: window.innerHeight,
            searchInputFocused: false,
            searchPillsEn: ['100', 'Joker', 'Fruit', 'Book', 'Big'],
            searchPillsJa: ['バカラ', 'ルーレット', 'ブラックジャック', 'ドラゴン', 'ワイルド'],
            isLoading: false,
            displayErrorMessage: false,
            searchString: '',
            searchResults: null,
            popularGames: null,
            recentlyPlayedGames: null,
            provider: '',
            providers: null,
            isLoadingProviders: false,
            isLoadingEnticingGames: false,
            showRollingEffect: true,
            scrollCounter: 0,
            wrapperScrollOffset: 0,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            debounceSearch: () => {
                /* Is replaced by debounce function after mount. Comment for sonarqube. */
            },
            debounceResize: () => {
                /* Is replaced by debounce function after mount. Comment for sonarqube. */
            },
        } as Data;
    },
    watch: {
        searchString(...args) {
            this.showRollingEffect = false;
            this.debounceSearch(...args);
        },
        provider(...args) {
            this.showRollingEffect = false;
            this.debounceSearch(...args);
        },
        overlayActive(overlayActiveBecameTrue) {
            if (overlayActiveBecameTrue && !this.providers?.length) {
                this.loadProviders();
            }
            if (overlayActiveBecameTrue && !this.recentlyPlayedGames?.recently_played?.length && !this.popularGames?.popular?.length) {
                if (this.onGamePage) {
                    this.loadEnticingGames();
                } else {
                    this.loadPopularGames();
                } 
            }
        },
        focused(focusedBecameTrue) {
            if (focusedBecameTrue) {
                if (this.useOverlay) {
                    disablePageScroll([this.$refs.searchResultsWrapper as ScrollableTarget, this.$refs.searchPillsWrapper as ScrollableTarget] as ScrollableTarget);
                }
                        
                this.pushToDataLayer({
                    event: 'Search Focused',
                    category: 'Website - Search',
                    label: 'Search',
                    action: 'Focus',
                });
                this.onCalculateWindowSize();
            } else {
                enablePageScroll([this.$refs.searchResultsWrapper as ScrollableTarget, this.$refs.searchPillsWrapper as ScrollableTarget] as ScrollableTarget);
                /* Programmaticaly remove focus from either inputs if user exits focus */
                (this.$refs.providerDropdown as HTMLSelectElement).blur();
                (this.$refs.searchInput as HTMLInputElement).blur();
            }
        },
    },
    computed: {
        pageSize(): number {  
            if (this.windowInnerWidth >= 1024) return 10;
            if (this.windowInnerWidth >= 768) return 8;
            if (this.windowInnerWidth >= 576) return 6;
            return 8;
        },
        spkContainerRadius(): string {
            if ((!this.overlayActive && this.windowInnerWidth >= 576) || this.windowInnerWidth >= 1024) {
                return 'primary';
            }

            return 'none';
        },
        showSearch(): boolean {
            if (this.overlayOnly) {
                return false;
            }
            return true;
        },
        onGamePage(): boolean {
            return window.location.href.indexOf('/play/') > -1;
        },
        useOverlay(): boolean {
            if (this.overlayOnly) {
                return true;
            }
            return this.windowInnerWidth <= resizeWidthThreshold;
        },
        overlayActive(): boolean {
            return this.focused && this.useOverlay;
        },
        searchPillsText(): string {
            return this.windowInnerWidth <= 576 ? this.translations.others_searched_for : this.translations.others_searched_for_desktop;
        },
        searchPillsEmpty(): boolean {
            return this.searchPills === null || this.searchPills.length === 0;
        },
        searchPills(): string[] | null {
            if (this.playerLanguage === 'ja' && this.environment === 'RDGE') {
                return this.searchPillsJa;
            }
            return this.searchPillsEn;
        },
        gamesArray(): Game[] {
            /* Return either search results or popular games */
            if (this.searchResults && this.searchResults.search) {
                if (this.searchResults.search.games?.length > 0) {
                    return this.searchResults.search.games;
                }
                if (this.popularGames?.popular?.length) {
                    return this.popularGames?.popular;
                }
            }
            return [];
        },
        searchableGamesCount(): number {
            if (this.isLoadingProviders) {
                return 0;
            }
            const foundProvider = this.providers?.find(provider => provider.code === this.provider);

            if (foundProvider) {
                return foundProvider.count;
            }

            let foundTotalGames = 0;
            this.providers?.forEach((provider) => {foundTotalGames += provider.count});
            return foundTotalGames;
        },
        selectedProviderName(): string {
            const foundProvider = this.providers?.find(provider => provider.code === this.provider);
            return foundProvider !== undefined ? foundProvider.name : '';
        },
        providerDropdownTop(): string{
            let dropDownTop = this.translations.filter;

            if (this.isLoadingProviders) {
                dropDownTop = this.translations.loading;
            } else if (!this.isLoadingProviders && !this.providers) {
                dropDownTop = this.translations.failed_to_fetch_providers;
            } else if (this.selectedProviderName) {
                dropDownTop = this.selectedProviderName;
            }

            return dropDownTop;
        },
        overlayOnly(): boolean {
            return Boolean(this.webfrontParams.overlayOnly) || false;
        },
        playerCurrency(): string {
            return this.webfrontParams.currency || '';
        },
        playerLanguage(): string {
            return this.webfrontParams.language || 'en';
        },
        numberOfDisplayedResults(): number {
            return this.searchResults?.search?.games?.length || 0;
        },
        numberOfTotalResults(): number {
            return this.searchResults?.search?.total || 0;
        },
        searchIsActive(): boolean {
            return this.searchString?.length > 0;
        },
        hasGameResults(): boolean {
            return !!this.searchResults?.search?.games?.length;
        },
        hasEnoughDataToSearch(): boolean {
            return !!this.searchString?.length || !!this.provider?.length;
        },
        displayResults(): boolean {
            return (
                !this.isLoading &&
                this.hasGameResults &&
                !this.displayErrorMessage
            );
        },
        noResultsOrPopularFound(): boolean {
            return (
                this.searchResults?.search?.total === 0 &&
                !!this.popularGames?.popular?.length
            );
        },
        noResultsFound(): boolean {
            return (
                !this.isLoading &&
                this.searchIsEmpty &&
                !this.displayErrorMessage
            );
        },
        searchIsEmpty(): boolean {
            return this.searchResults?.search?.total === 0;
        },
        canLoadMore(): boolean {
            if (!this.searchResults) {
                return false;
            }

            return (
                this.searchResults.search.total > this.searchResults.search.games?.length
            );
        },
        nextPage(): number {
            if (this.searchResults?.search?.games?.length) {
                return Math.ceil(
                    this.searchResults.search.games.length / this.pageSize
                );
            }
            return 0;
        },
    },
    methods: {
        t,
        slotRollingEffect(): string {
            return this.t(this.translations.input_placeholder, {
                total: '<span class="slot"><span class="number active first"><span class="num">0</span><span class="num">1</span><span class="num">2</span><span class="num">3</span><span class="num">4</span><span class="num">5</span><span class="num">6</span><span class="num">7</span><span class="num">8</span><span class="num">9</span></span><span class="number active second"><span class="num">0</span><span class="num">1</span><span class="num">2</span><span class="num">3</span><span class="num">4</span><span class="num">5</span><span class="num">6</span><span class="num">7</span><span class="num">8</span><span class="num">9</span></span><span class="number active third"><span class="num">0</span><span class="num">1</span><span class="num">2</span><span class="num">3</span><span class="num">4</span><span class="num">5</span><span class="num">6</span><span class="num">7</span><span class="num">8</span><span class="num">9</span></span><span class="number active fourth"><span class="num">0</span><span class="num">1</span><span class="num">2</span><span class="num">3</span><span class="num">4</span><span class="num">5</span><span class="num">6</span><span class="num">7</span><span class="num">8</span><span class="num">9</span></span></span>'
            })
        },
        stopNumberByIndex(index: number, stopAtNumber: string): Promise<boolean> {
            return new Promise((resolve) => {
                const numberValue = parseInt(stopAtNumber, 10);
                const slotRollerEl = (this.$refs.slotRoller as HTMLElement);
                if (!slotRollerEl) {
                    /* The element doesn't exist. Player focused the input field before end of animation */
                    resolve(false);
                }
                const numberEl = (slotRollerEl.querySelectorAll('.number')[index] as HTMLElement);
                const numberHeight = (numberEl.querySelector('.num') as HTMLElement).clientHeight ?? 18;
                numberEl.addEventListener('animationiteration', () => {
                    numberEl.classList.remove('active');
                    numberEl.style.transition = `transform ${100 * numberValue}ms ease-in`;
                    numberEl.style.transform = `translateY(-${numberHeight * numberValue}px)`;
                    setTimeout(() => resolve(true), 100 * numberValue);
                }, { once: true })
            });
        },
        stopRoll(): void {
            const splitStop = this.searchableGamesCount.toString().split("");
            const allNumbersDone = [];
            /* Stop the individual numbers in the correct order */
            if (splitStop[0]) {
                allNumbersDone.push(this.stopNumberByIndex(0, splitStop[0]))
            }
            if (splitStop[1]) {
                allNumbersDone.push(this.stopNumberByIndex(1, splitStop[1]))
            }
            if (splitStop[2]) {
                allNumbersDone.push(this.stopNumberByIndex(2, splitStop[2]))
            }
            if (splitStop[3]) {
                allNumbersDone.push(this.stopNumberByIndex(3, splitStop[3]))
            }
            Promise.all(allNumbersDone).finally(() => {
                this.showRollingEffect = false;
            })
            
        },
        getSgaBarHeight(): number {
            /* Try to find the sgaBar if it exists */
            const sgaBar = document.querySelector('.sga-locked-fields')
                ? document.querySelector('.sga-locked-fields')
                : document.querySelector('.sga-locked-fields-content');

            let sgaBarHeight = 0;
            if (sgaBar){
                sgaBarHeight = sgaBar.clientHeight;
            }
            return sgaBarHeight;
        },
        getGlobalBannerHeight(): number {
            /* Try to find the global banner if it exists */
            const globalBanner = document.querySelector('.global-banner-notification-wrapper');

            let globalBannerHeight = 0;
            if (globalBanner){
                globalBannerHeight = globalBanner.clientHeight;
            }
            return globalBannerHeight;
        },
        handleKeydown(event: KeyboardEvent): void {
            if (event.key === "Escape") {
                if (this.overlayActive) {
                    (this.$refs.providerDropdown as HTMLSelectElement).blur();
                    (this.$refs.searchInput as HTMLInputElement).blur();
                    this.focused = false;
                    /* If the user exits the overlay we need to reset the history state so the back button works when enabling the overlay again */
                    window.history.replaceState({
                        searchOverlayOpen: false
                    },'');
                }
            }
        },
        onOrientationChange(): void{
            this.scrollCounter = 0;
            setTimeout(() => {
                (this.$refs.searchResultsWrapper as Element).scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto'
                });
            }, 250);
        },
        onSearchPillClick(searchPillTerm: string, index: number): void {
            if (this.focused) {
                if(this.searchInputFocused){
                    (this.$refs.searchInput as HTMLInputElement).focus();
                }
            } else {
                (this.$refs.searchInput as HTMLInputElement).focus();
                (this.$refs.providerDropdown as HTMLSelectElement).blur();
                (this.$refs.searchInput as HTMLInputElement).blur();
            }

            this.pushToDataLayer({
                event: 'Search Pill',
                category: 'Website - Search',
                label: searchPillTerm,
                action: 'Click',
                value: index,
            });

            this.searchString = searchPillTerm;
            (this.$refs.searchInput as HTMLInputElement).value = searchPillTerm;
        },
        onCalculateWindowSize(): void {
            /* Programaticaly set the height of search-container to fit ios devices */
            const sgaBarHeight = this.getSgaBarHeight();
            const globalBannerHeight = this.getGlobalBannerHeight();
            const deviceHeight = window.innerHeight;
            document.documentElement.style.setProperty('--search-focus-height', `${deviceHeight}px`);
            document.documentElement.style.setProperty('--search-focus-sgabar', `${sgaBarHeight}px`);
            document.documentElement.style.setProperty('--search-focus-global-banner', `${globalBannerHeight}px`);
        },
        onResize(): void {
            /* Force the device out of overlay and reset everything */
            this.onCalculateWindowSize();
            if (!this.searchInputFocused && !this.useOverlay) {
                this.focused = false;
                enablePageScroll([this.$refs.searchResultsWrapper as ScrollableTarget, this.$refs.searchPillsWrapper as ScrollableTarget] as ScrollableTarget);
                (this.$refs.providerDropdown as HTMLSelectElement).blur();
                (this.$refs.searchInput as HTMLInputElement).blur();
            }
        },
        onFocusInputfield(): void {
            this.searchInputFocused = true;
            this.onFocus();
        },
        onFocus(): void { 
            this.focused = true;
            if (this.useOverlay) {

                /* Enable the use of the browser back button to remove the overlay */
                window.history.pushState({
                    searchOverlayOpen: true
                },'');
                
                setTimeout(() => {
                    if (!this.overlayOnly) {
                        window.scrollTo(0, 0);
                        document.body.scrollTop = 0;
                    }

                    (this.$refs.searchResultsWrapper as Element).scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'auto'
                    });
                }, 250);
            }
        },
        onBlurInputfield(): void {
            /* Delay the blur to take into account the blur check on the clear button */
            setTimeout(() => {                
                this.searchInputFocused = false;
            }, 100);
        },
        closeOverlay(): void {
            if (this.overlayActive) {
                window.history.replaceState({
                    searchOverlayOpen: false
                },'');
                window.postMessage({ action: 'GAME_SEARCH_CLOSE' }, window.origin);
            }
            
            this.focused = false;
            
            if (!this.overlayOnly && this.hasGameResults) {
                /* Scroll to the same position in searchContainer so that the view doesn't jump around when exiting overlay */ 
                const wrapper = (this.$refs.searchResultsWrapper as Element);
                const sgaBarHeight = this.getSgaBarHeight();
                const searchFocusTopbarHeight = (this.$refs.searchFocusTopbar as Element).getBoundingClientRect().height + sgaBarHeight + (this.$refs.searchPillsWrapper as Element).getBoundingClientRect().height;
                this.wrapperScrollOffset = wrapper.scrollTop;
                
                this.$nextTick().then(() => {
                    const scrollValue = this.hasGameResults
                        ? (wrapper.getBoundingClientRect().top - searchFocusTopbarHeight + this.wrapperScrollOffset)
                        : (this.$refs.searchFocusTopbar as Element).getBoundingClientRect().top - 96;
                    window.scrollTo({
                        top: scrollValue,
                        left: 0,
                        behavior: 'auto'
                    });
                });
            }
        },
        onKeyup(ev: KeyboardEvent): void {
            const key = ev.code || ev.keyCode;
            if (ev.target && key === 'Enter' || key === 13) {
                (ev.target as HTMLInputElement).blur();
            }
        },
        onInputChange(ev: Event): void{
            this.searchString = (ev.target as HTMLInputElement)?.value;
        },
        onLoadMore(): void {
            this.pushToDataLayer({
                event: 'Load more results',
                category: 'Website - Search',
                label: 'Load more results',
                action: 'Click',
                value: this.searchResults?.search.games.length,
                dimension1: this.searchString,
                dimension4: this.provider,
            });
            this.search();
        },
        getRequestParameters(): Record<string, string> {
            return {
                /* If user isn't logged in playertoken won't be set from webfront */
                player_token: this.webfrontParams.playertoken ?? null,
                channel: this.webfrontParams.channel,
                country: this.webfrontParams.country,
                currency: this.webfrontParams.currency,
                language: this.webfrontParams.language,
                site_id: this.webfrontParams.siteid,
                player_flag: this.webfrontParams.player_flag ?? 0,
            }
        },
        getSpkPillSize(): string {
            return (this.windowInnerWidth >= 1023) ? 'm' : 's';
        },
        async loadPopularGames(): Promise<void> {
            const params = this.getRequestParameters();
            const urlSearchParams = new URLSearchParams({
                ...params,
                page_size: "10",
                page: "0",
            });
            const url = `${this.baseUrl}/ajax/popular-games?${urlSearchParams.toString()}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${window.JWToken}`,
                    },
                })
                if (!response.ok) {
                    throw response;
                }
                const responseData = await response.json();
                if (response.status === 200) {
                    this.popularGames = responseData;
                }
            } catch (error) {
                this.popularGames = null;
            }
        },
        async loadEnticingGames(): Promise<void> {
            this.isLoadingEnticingGames = true;
            const params = this.getRequestParameters();
            const urlSearchParams = new URLSearchParams({
                ...params,
                page_size: "10",
                page: "0",
            });
            const url = `${this.baseUrl}/ajax/enticing-games?${urlSearchParams.toString()}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${window.JWToken}`,
                    },
                })
                if (!response.ok) {
                    throw response;
                }
                const responseData = await response.json();
                if (response.status === 200) {
                    if (responseData.recently_played?.length) {
                        this.recentlyPlayedGames = responseData;
                    } else {
                        this.popularGames = responseData;
                    }
                }
            } catch (error) {
                this.recentlyPlayedGames = null;
                this.popularGames = null;
            }
            this.isLoadingEnticingGames = false;
        },
        async loadProviders(): Promise<void> {
            this.isLoadingProviders = true;
            const params = this.getRequestParameters();
            const urlSearchParams = new URLSearchParams(params);
            const url = `${this.baseUrl}/ajax/game/providers?${urlSearchParams.toString()}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${window.JWToken}`,
                    },
                })
                if (!response.ok) {
                    throw response;
                }
                const responseData = await response.json();
                if (response.status === 200) {
                    this.providers = responseData;
                }
            } catch (error) {
                this.providers = null;
            }
            this.isLoadingProviders = false;
            this.stopRoll();
        },
        async search(): Promise<void> {
            this.isLoading = true;
            this.displayErrorMessage = false;
            clearRequestStack();
            const controller = new AbortController();
            requestStack.push(controller);
            const params = {
                ...this.getRequestParameters(), 
                search_term: this.searchString,
                page_size: this.pageSize.toString(),
                page: this.nextPage.toString(),
                provider: this.provider,
            }
            const urlSearchParams = new URLSearchParams(params);
            const url = `${this.baseUrl}/ajax/game/search?${urlSearchParams.toString()}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${window.JWToken}`,
                    },
                    signal: controller.signal,
                });
                if (!response.ok) {
                    throw response;
                }
                let responseData = await response.json();
                if (response.status === 200) {
                    if (responseData.total === 0 && !this.popularGames?.popular) {
                        this.loadPopularGames();
                    }

                    responseData = {
                        ...responseData,
                        games: this.formatGames(responseData.games ?? []),
                    }

                    if (this.searchResults?.search) {
                        if (responseData.games.length) {
                            this.searchResults.search.games = this.searchResults.search.games.concat(responseData.games);
                        }

                        this.searchResults.search.total = responseData.total;
                    } else {
                        this.searchResults = {
                            search: responseData,
                        };
                    }
                }

                this.pushToDataLayer({
                    event: 'Search',
                    category: 'Website - Search',
                    label: 'Search',
                    action: 'Search',
                    dimension1: this.searchString,
                    dimension4: this.provider,
                    value: responseData.total,
                });
            } catch (error: unknown) {
                /* Don't display error if request is canceled */
                if (!controller.signal.aborted) {
                    this.displayErrorMessage = true;
                }
            }
            this.isLoading = false;
        },
        formatGames(games: Game[]): Game[] {
            const formattedGames = games;
            if (games.length && this.playerLanguage && this.playerCurrency) {
                games.forEach((game, index) => {
                    if (game.jp_amount) {
                        formattedGames[index].formatted_jp_amount = new Intl.NumberFormat(this.playerLanguage, {
                            style: 'currency', 
                            currency: this.playerCurrency
                        }).format(game.jp_amount);
                    }
                })
            }
            return formattedGames;
        },
        clearSearchString(): void {
            /* If selected the clear button should not remove the focus of the inputfield */
            if (this.searchInputFocused) {
                (this.$refs.searchInput as HTMLDivElement).focus();
            }

            // Clear pending requests
            clearRequestStack();
            this.searchResults = null;
            this.searchString = '';
            this.isLoading = false;
            this.displayErrorMessage = false;
            this.debounceSearch();
        },
        pushToDataLayer(payload: Record<string, string | number | boolean | undefined | (() => void)>): void {
            if (typeof window.dataLayer?.push !== 'function' || !payload) {
                return;
            }
            window.dataLayer.push({
                ...payload,
                ...{
                    siteId: this.webfrontParams.siteid,
                    country: this.webfrontParams.country,
                    currency: this.webfrontParams.currency,
                    language: this.webfrontParams.language,
                    siteCode: this.webfrontParams.sitecode,
                    playerToken: this.webfrontParams.playertoken || null,
                },
            });
        },
        onScroll(): void {
            if (this.overlayActive) {
                this.onCalculateWindowSize();
                if (!this.overlayOnly && this.hasEnoughDataToSearch) {
                    const wrapper = (this.$refs.searchResultsWrapper as Element);
                    if (Math.ceil(wrapper.scrollTop) + wrapper.clientHeight >= wrapper.scrollHeight) {
                        this.scrollCounter += 1;

                        if (this.scrollCounter >= 5) {
                            this.focused = false;
                            this.scrollCounter = 0;

                            this.$nextTick().then(() => {
                                const viewportHeight = window.innerHeight;
                                window.scrollTo({
                                    top: wrapper.getBoundingClientRect().bottom - viewportHeight,
                                    left: 0,
                                    behavior: 'auto'
                                });
                            });
                        }
                    } else {
                        this.scrollCounter = 0;
                    }
                }
            }
        },
        receiveMessage(event: MessageEvent): void {
            if (this.overlayOnly && this.useOverlay && event.data.action === 'GAME_SEARCH_OPEN') {
                this.clearSearchString();
                this.provider = '';
                this.onFocus();
            }

            if (event.data.action === 'GLOBAL_BANNER_CLOSED') {
                document.documentElement.style.setProperty('--search-focus-global-banner', `0px`);
            }
        },
    },
    mounted(): void {
        (this.$refs.searchContainer as Element).addEventListener('touchmove', this.onScroll, { passive: true });
        window.addEventListener('resize', () => {
            this.windowInnerHeight = window.innerHeight;
            this.windowInnerWidth = window.innerWidth;
            this.debounceResize();
        });
        window.addEventListener('orientationchange', this.onOrientationChange);
        window.addEventListener('message', this.receiveMessage);
        window.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('popstate', (event: PopStateEvent) => {
            if (!event.state?.searchOverlayOpen && this.overlayActive) {
                this.closeOverlay();
            }
        })
    },
    created(): void { 
        if (!this.overlayOnly) {
            this.loadProviders();
        }

        this.debounceSearch = debounce(() => {
            clearRequestStack();
            this.searchResults = null;
            this.displayErrorMessage = false;

            if (this.hasEnoughDataToSearch) {
                this.search();
            }
        }, 300);
        this.debounceResize = debounce(() => {
            this.onResize();
        }, 300);
    },
    beforeUnmount(): void {
        this.debounceSearch?.cancel();
        this.debounceResize?.cancel();
        clearRequestStack();
        (this.$refs.searchContainer as Element).removeEventListener('touchmove', this.onScroll);
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.onOrientationChange);
        window.removeEventListener('message', this.receiveMessage);
        window.removeEventListener('keydown', this.handleKeydown);
    },
});

import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["is-logged-in", "is-favourited", "jackpot-amount", "url-alias", "display-name", "icon-url", "foreground-icon-url", "background-icon-url", "game-uuid", "badges", "live-data", "launch-origin", "position", "site-id", "player-flag", "country", "language", "currency"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("game-tile", {
    "search-result": "",
    class: "min-h-search-game-tile-height",
    "is-logged-in": _ctx.isLoggedIn ? 'true' : null,
    "is-favourited": _ctx.searchResult.is_favourite ? 'true' : null,
    "jackpot-amount": _ctx.searchResult.formatted_jp_amount,
    "url-alias": _ctx.searchResult.url_alias,
    "display-name": _ctx.searchResult.display_name,
    "icon-url": _ctx.searchResult.icon,
    "foreground-icon-url": _ctx.searchResult.foreground_icon,
    "background-icon-url": _ctx.searchResult.background_icon,
    "game-uuid": _ctx.searchResult.game_id,
    badges: _ctx.badges,
    "live-data": _ctx.liveData,
    "launch-origin": _ctx.launchOrigin,
    position: _ctx.position,
    "site-id": _ctx.injectedParams.siteid,
    "player-flag": _ctx.injectedParams.player_flag ?? '1',
    country: _ctx.injectedParams.country,
    language: _ctx.injectedParams.language,
    currency: _ctx.injectedParams.currency
  }, null, 8 /* PROPS */, _hoisted_1))
}